import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getMultipleFilenames } from 'helper';
import { EFileTypes } from 'enums';
import { IRootState } from 'store/types';
import AccordionField from 'components/accordionField/AccordionField';
import ImageWrapper from 'components/imageWrapper/ImageWrapper';
import Modal from 'components/modal/Modal';

const ScannedDocument = () => {
  const { t } = useTranslation();
  const state = useSelector((rootState: IRootState) => rootState);
  const { transactionCode, transactionData } = state.reviewResult;
  const pageEnabled = transactionData.transactionModules.a4Scan;
  const [filename, setFilename] = useState('');
  const filenames = useMemo(() => getMultipleFilenames(transactionData, EFileTypes.a4Scan), [transactionCode]);

  const content = useMemo(() => {
    return (
      <div className="a4-additional-scanned-images-container">
        <div className='a4-additional-scanned-images'>
          {
            filenames.map((filename: string, index: number) => (
              <div className='a4-document-item-content' key={index}>
                <ImageWrapper
                  transactionCode={transactionCode}
                  filetype={EFileTypes.a4Scan}
                  filename={filename}
                  setFilename={setFilename}
                />
              </div>
            ))
          }
        </div>
      </div>
    );
  }, [transactionCode, transactionData]);

  return (<>
    <div className='a4-content'>
      <AccordionField
        title={t('a4.heading')}
        body={content}
        className={'accordion-container'}
        showStatus={pageEnabled}
        eventKey={1}
        preventToggle={!pageEnabled}
      />
      {!!filename && <Modal
        show={!!filename}
        onHide={() => setFilename('')}
      >
        <div className='a4-image-wrapper-container'>
          <ImageWrapper
            transactionCode={transactionCode}
            filetype={EFileTypes.a4Scan}
            filename={filename}
            enableZoom={true}
            original={true}
          />
        </div>
      </Modal>}
    </div>
  </>);
};

export default ScannedDocument;