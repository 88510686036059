import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SwitchLanguage from 'components/lang/SwitchLanguage';
import SingleButton from 'components/buttonTypes/SingleButton';
import { BUTTON_STYLE } from 'constants/Static';
import { IRootState } from 'store/types';
import { AuthorizeAPI } from 'helper/api/route';
import Loader from 'components/loader/Loader';
import * as commonActions from 'store/features/common/actions';

enum ELoginStatus {
  success = 'success',
  fail = 'fail',
  notPerformed = 'notPerformed',
}

const ExternalLogin = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const state: IRootState = useSelector((rootState: IRootState) => rootState);
  const { ssoConfig } = state.common?.appConfig || {};
  const [loginStatus, setLoginStatus] = useState(ELoginStatus.notPerformed);

  useEffect(() => {
    const handlePageShow = (event: PageTransitionEvent) => {
      if (event.persisted) {
        // The page was restored from bfcache
        dispatch(commonActions.setLoading(false));
      }
    };
    window.addEventListener('pageshow', handlePageShow);
    return () => window.removeEventListener('pageshow', handlePageShow);
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const error = searchParams.get('error');
    setLoginStatus(error === 'true' ? ELoginStatus.fail : ELoginStatus.success);
  }, [location.search]);

  const clickHandler = () => {
    dispatch(commonActions.setLoading(true));
    AuthorizeAPI().then((response) => {
      if (response.data.url) {
        window.location.href = response.data.url;
      } else {
        dispatch(commonActions.setLoading(false));
        setLoginStatus(ELoginStatus.fail);
      }
    }).catch(() => {
      dispatch(commonActions.setLoading(false));
      setLoginStatus(ELoginStatus.fail);
    });
  }

  const errorMessages = t('login.sso.error.message').split('\n');

  return (
    <div className="external-login-content">
      <div className="container">
        <div className="login-container">
          <div className="identity-container">
            <img src={ssoConfig?.logo} alt="logo" />
            <span className="identity-text">
              {t('login.sso.message')}
            </span>
            {loginStatus === ELoginStatus.fail && (
              <div className="sso-fail-message">
                <span className='sso-fail-header'>{errorMessages[0]}</span>
                <ul className='sso-fail-list'>
                  {errorMessages.slice(1, -1).map((message, index) => (
                    <li className='sso-fail-list-item' key={index}>{message}</li>
                  ))}
                </ul>
                <span className='sso-fail-footer'>{errorMessages[errorMessages.length - 1]}</span>
              </div>
            )}
            <div className="button-container">
              <SingleButton
                isActive={!state.common?.loading}
                isDisabled={state.common?.loading}
                onClick={clickHandler}
                textButton={'Login'}
                variant={BUTTON_STYLE.OUTLINE_SECONDARY}
                radiusButton={BUTTON_STYLE.CIRCLE_BUTTON}
              />
              {state.common?.loading && <div className="loader-container"><Loader /></div>}
            </div>
          </div>
        </div>
      </div>
      <SwitchLanguage />
    </div>
  );
};

export default ExternalLogin;
