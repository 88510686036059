import axios from 'util/axios';
import { CancelToken } from 'axios';

import {
  TCompareFields,
  TGenericMessage,
  TLockTransaction,
  TResource,
  TResourceParams,
  TTransactionList,
  TTransactionsQueryParams,
} from 'types';
import {
  TSpecimen,
  TTransactionData,
  TLockTransactionRes,
  TManualReview,
} from 'store/features/reviewResult/types';
import { TAgent } from 'store/features/agentManagement/types';
import { TAppConfig } from 'store/features/common/types';

type TRequiredFieldsParams = {
  documentType: string;
  documentCountry: string;
  extraLabel?: string;
};

export const SignInAPI = (email: string, password: string, turnstileToken: string) => (
  axios.post<TGenericMessage>('/login', { email, password, turnstileToken })
);

export const GetAgentAPI = () => axios.get<TAgent>('/agent-info');

export const AuthorizeAPI = () => axios.get<{ url: string }>('/oauth/authorize');

export const GetSpecimensAPI = (documentType: string, documentCountry: string) => (
  axios.get<TSpecimen[]>('/specimens', { params: { documentType, documentCountry }})
);

export const GetTransactionDataAPI = (transactionCode: string) => (
  axios.get<TTransactionData>(`/transaction-data/${transactionCode}`)
);

export const LockTransactionAPI = (data: TLockTransaction, datasetId?: number) => (
  axios.put<TLockTransactionRes>('/lock', data, { params: { datasetId } })
);

export const ExportFileAPI = (params: { datasetId: number }) => axios.get('/export', { params });

export const GetTransactionsAPI = (params: TTransactionsQueryParams) => (
  axios.get<TTransactionList>('/transactions', { params })
);

export const GetRequiredFieldsAPI = (transactionCode: string, params: TRequiredFieldsParams) => (
  axios.get(`/required-fields/${transactionCode}`, { params })
);

export const CompleteTransactionAPI = (transactionCode: string, data: TManualReview) => (
  axios.put(`/complete/${transactionCode}`, data)
);

export const SendEmailAPI = (data: any) => axios.post('/send-email', { data });

export const GetAppConfigsAPI = () => axios.get<TAppConfig>('/configs');

export const UnlockTransactionAPI = (transactionCode: string) => (
  axios.put<TGenericMessage>(`/unlock/${transactionCode}`)
);

export const LogErrorAPI = (message: string) => axios.post('/log', message);

export const GetResourceAPI = (transactionCode: string, params: TResourceParams, token: CancelToken) => (
  axios.get<TResource>(`/resources/${transactionCode}`, { params, cancelToken: token })
);

export const CompareFieldsAPI = (transactionCode: string, data: TCompareFields) => (
  axios.post(`/compare-fields/${transactionCode}`, data)
);

export const ForgotPasswordAPI = (email: string, lang: string, turnstileToken: string) => (
  axios.post<TGenericMessage>('/reset-password', { email, turnstileToken }, { params: { lang } })
);

export const SetNewPasswordAPI = (
  agentId: number,
  password: string,
  activationToken: string,
  lang: string,
  turnstileToken: string,
) => (
  axios.post<TGenericMessage>(`/set-password/${agentId}`, {
    password, activationToken, turnstileToken,
  }, { params: { lang } })
);

export const ValidateTokenAPI = (activationToken: string) => (
  axios.post('/validate-token', { activationToken })
);