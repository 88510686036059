import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReactComponent as CloseLogo } from 'images/close.svg';
import { ReactComponent as SearchIcon } from 'images/search.svg';
import { IRootState } from 'store/types';
import { getSelectedDataset } from 'helper';
import { ESearchType } from 'enums';
import { TLockTransaction, TReviewedTransaction, TTransactionItem, TTransactionsQueryParams } from 'types';
import { GetTransactionsAPI } from 'helper/api/route';
import Loader from 'components/loader/Loader';
import StyledText from 'components/styledText/StyledText';
import { HISTORY } from 'constants/Static';

type TSearchOptions = {
  value: string;
  result: TTransactionItem[];
  loading: boolean;
  status: 'success' | 'failed' | 'notPerformed';
}

const SearchTransaction = (props: {
  close: Function;
  startReview: (options?: TLockTransaction) => void;
  openSummary: (transaction: TReviewedTransaction) => void;
  searchType: ESearchType;
}) => {
  const { t } = useTranslation();
  const { close, startReview, openSummary, searchType } = props;
  const state = useSelector((rootState: IRootState) => rootState);
  const { datasets = [] } = state?.agentProfile?.agent || {};
  const dataset = useMemo(() => getSelectedDataset(datasets), [datasets]);
  const [options, setOptions] = useState<TSearchOptions>({
    loading: false,
    value: '',
    result: [],
    status: 'notPerformed',
  });

  const handleSearch = (event: React.KeyboardEvent<HTMLInputElement | HTMLDivElement>) => {
    if (options.value && event.key === 'Enter') {
      setOptions((prev) => ({ ...prev, loading: true, result: [] }));
      const params: TTransactionsQueryParams = { datasetId: dataset?.id };
      if (searchType === ESearchType.transactionCode) {
        params.transactionCode = options.value;
      } else {
        params.orderId = options.value;
      }
      GetTransactionsAPI(params).then((response) => {
        if (response.data?.items.length) {
          setOptions((prev) => ({ ...prev, status: 'success', loading: false, result: response.data.items }));
        } else {
          setOptions((prev) => ({ ...prev, status: 'failed', loading: false, result: [] }));
        }
      }).catch(() => {
        setOptions((prev) => ({ ...prev, status: 'failed', loading: false, result: [] }));
      });
    }
  };

  const handleOpenTransaction = (transaction: TTransactionItem) => () => {
    if (HISTORY in transaction) {
      openSummary(transaction);
    } else {
      startReview({ transactionCode: transaction.transactionCode });
    }
    close(false);
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value.trim();
    setOptions((prev) => ({ ...prev, value: newValue, status: 'notPerformed', result: [] }));
  };

  const SearchResultNotFound = () => {
    const transactionIdentifier = searchType === ESearchType.orderId ?
      'dashboard.modal.order-id' : 'dashboard.modal.transaction-code';
    return <div className="search-failed">
      <div>
        <div className="failed-text">
          <StyledText data={[
            { text: t(transactionIdentifier) },
            { text: options.value, type: 'bold' },
            { text: t('dashboard.modal.not-found.for-review') },
          ]} isTranslated={true} />
        </div>
      </div>
      <div
        className=" button try-again-button"
        role="button"
        onClick={() => setOptions((prev) => ({ ...prev, value: '', result: [], status: 'notPerformed' }))}
        tabIndex={-1}
      >
        <span>{t('dashboard.search.result.try-again')}</span>
      </div>
    </div>
  };


  const SearchResultFound = () => (<>
    {options.result.map((transaction, index) =>
      <div
        className="search-success"
        tabIndex={0}
        key={index}
      >
        <div className="brand">
          <span>{dataset?.name || '-'}</span>
        </div>
        <div className="tid">
          <span>{options.value}</span>
        </div>
        <div className="review">
          <span
            onClick={handleOpenTransaction(transaction)}
            role="button"
            tabIndex={-1}
            className={'text'}
          >
            {HISTORY in transaction ? t('dashboard.reviewed-tab.summary.btn')
              : t('dashboard.open-tab.transaction-review.btn')}
          </span>
        </div>
      </div>
    )}
  </>);

  const searchMessage = searchType === ESearchType.transactionCode ?
    'search.transaction-id.message' : 'search.order-id.message';

  return (
    <div className='modal-wrapper'>
      <div className='modal-header'>
        <div className="dashboard-modal-title" tabIndex={-1}>
          <p>{t(searchMessage)}</p>
          <div className="search-input">
            <input
              className="trcode-input"
              autoFocus
              type="text"
              inputMode="numeric"
              value={options.value}
              onChange={(e) => handleOnChange(e)}
              onKeyDown={(e) => handleSearch(e)}
            />
            <SearchIcon />
          </div>
        </div>
        <div
          className="button close-modal-button"
          role="button"
          onClick={() => close(false)}
        >
          <CloseLogo />
        </div>
      </div>
      {(options.status !== 'notPerformed' || options.loading) && <div className='modal-body'>
        {options.loading && <div className="loader-container"><Loader /></div>}
        {options.status === 'success' ? <SearchResultFound /> : <SearchResultNotFound />}
      </div>}
    </div>
  );
};

export default SearchTransaction;
