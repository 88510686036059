import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';

import SignIn from 'pages/signIn/SignIn';
import ExternalSignIn from 'pages/signIn/ExternalSignIn';
import Dashboard from 'pages/dashboard/Dashboard';
import Summary from 'pages/summary/Summary';
import PublicRoute from 'routing/PublicRoute';
import PrivateRoute from 'routing/PrivateRoute';
import * as rootActions from 'store/actions';
import * as commonActions from 'store/features/common/actions';
import { IRootState } from 'store/types';
import LogOut from 'pages/logout/LogOut';
import { ERoutePaths } from 'enums';
import ForgotPassword from 'pages/forgotPassword/ForgotPassword';
import NewPassword from 'pages/newPassword/NewPassword';
import ConditionalRedirect from 'routing/ConditionalRedirect';
import TABoarding from 'pages/taBoard/TABoarding';
import Review from 'pages/review/Review';
import { DEEP_LINK_ALLOWED_URLS, ENFORCED_REDIRECT_URLS } from 'constants/Static';
import { getAllowedTabs } from 'helper';

const Routing = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state: IRootState = useSelector((rootState: IRootState) => rootState);
  const { transactionData } = state.reviewResult;
  const { transactionModules } = transactionData || {};
  const appConfig = state.common?.appConfig;
  const location = useLocation();
  const [onLine, setOnline] = useState(navigator.onLine);

  useEffect(() => {
    const tid = new URLSearchParams(location.search).get('tid');
    const lang = new URLSearchParams(location.search).get('lang');
    if (tid) {
      const deeppinkTransaction = DEEP_LINK_ALLOWED_URLS.some((url) => location.pathname.includes(url))
        ? tid : '';
      dispatch(commonActions.setDefaultTransactionCode(deeppinkTransaction));
      window.history.replaceState({}, '', location.pathname);
    }
    if (lang && appConfig?.lang?.langs.includes(lang)) {
      i18n.changeLanguage(lang);
    }
    if (transactionData && location.pathname === ERoutePaths.dashboard) {
      dispatch(rootActions.resetSession());
    }
    if (ENFORCED_REDIRECT_URLS.find((url => location.pathname.startsWith(url))) && location.key === 'default') {
      navigate(ERoutePaths.dashboard);
    }
    window.addEventListener('popstate', () => window.location.replace(ERoutePaths.root), false);
    window.addEventListener('online', () => setOnline(true));
    window.addEventListener('offline', () => setOnline(false));
    return () => {
      window.removeEventListener('popstate', () => ({}));
      window.removeEventListener('online', () => setOnline(true));
      window.removeEventListener('offline', () => setOnline(false));
    };
  }, [location]);

  return (
    <>
      <Modal show={!onLine} size='lg' className='connection-lost-modal'>
        <Modal.Body>
          <div className='connection-lost'>{t('error.no-internet.message')}</div>
        </Modal.Body>
      </Modal>
      <Routes>
        <Route
          path={ERoutePaths.signIn}
          element={<PublicRoute component={!appConfig?.ssoConfig ? <SignIn /> : <ExternalSignIn />} />}
        />
        <Route
          path={ERoutePaths.forgottenPassword}
          element={<PublicRoute component={<ForgotPassword />}/>}
        />
        <Route
          path={ERoutePaths.logOut}
          element={<PublicRoute component={<LogOut />}/>}
        />
        <Route
          path={ERoutePaths.setPassword}
          element={<PublicRoute component={<NewPassword />}/>}
        />
        <Route
          path={ERoutePaths.dashboard}
          element={<PrivateRoute component={<Dashboard />}/>}
        />
        <Route
          path={`${ERoutePaths.taBoarding}/:transactionCode`}
          element={<PrivateRoute component={<TABoarding />}/>}
        />
        {transactionModules && getAllowedTabs(transactionModules).map((item, index) => <Route
          key={index}
          path={`${item.url}/:transactionCode`}
          element={<PrivateRoute component={<Review />}/>}
        />)}
        <Route
          path={`${ERoutePaths.summary}/:transactionCode/:reviewId`}
          element={<PrivateRoute component={<Summary />}/>}
        />
        <Route path="*" element={<ConditionalRedirect />} />
      </Routes>
    </>
  );
};

export default Routing;
