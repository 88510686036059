import { EAnswers, EZones } from 'enums';
import { TManualCheckResult, TRequiredFields, TTransactionModules } from 'store/features/reviewResult/types';
import { REGULAR_FIELDS } from 'constants/Fields';

type TResultList = {
  title: string;
  result: string;
  resultKey: string;
};

const generateCheckResult = (manualCheckResult: TManualCheckResult) => {
  const data: TResultList[] = [];
  const { identityReview, addressReview, a4ScanReview } = manualCheckResult;
  const { documentValidationReview, faceVerificationReview, livenessDetectionReview } = identityReview;
  const [{ result = '' }] = documentValidationReview;
  if (result) {
    data.push({
      result,
      title: 'footer.check.doc.message',
      resultKey: `review-footer.answer-${result}.title`,
    });
  }
  if (faceVerificationReview.result) {
    data.push({
      result: faceVerificationReview.result,
      title: 'footer.check.face.message',
      resultKey: `review-footer.answer-${faceVerificationReview.result}.title`,
    });
  }
  if (livenessDetectionReview.result) {
    data.push({
      result: livenessDetectionReview.result,
      title: 'footer.check.liveness.message',
      resultKey: `review-footer.answer-${livenessDetectionReview.result}.title`,
    });
  }
  if (addressReview.result) {
    data.push({
      result: addressReview.result,
      title: 'footer.check.address.message',
      resultKey: `review-footer.answer-${addressReview.result}.title`,
    });
  }
  if (a4ScanReview.result) {
    data.push({
      result: a4ScanReview.result,
      title: 'footer.check.a4.message',
      resultKey: `review-footer.answer-${a4ScanReview.result}.title`,
    });
  }
  return data;
}

const acceptanceCheck = (
  t: Function,
  transactionModules: TTransactionModules,
  manualCheckResult: TManualCheckResult,
) => {
  const { addressReview, identityReview, a4ScanReview, acceptanceChecks } = manualCheckResult || {};
  const { documentValidationReview, livenessDetectionReview, faceVerificationReview } = identityReview || {};
  const {
    documentCountry,
    documentType,
    extraDetail,
    dataVerification,
    extraDetails,
  } = documentValidationReview?.[0] || {};
  const { fieldNames, zones, fields } = dataVerification || {};
  const {
    documentValid,
    documentPresented,
    documentPhysicalSigns,
    documentClearlyVisible,
    faceVerificationSamePerson,
    faceVerificationPhysicalSigns,
    livenessRealVideo,
  } = acceptanceChecks || {};

  const errorMessages = [];
  if(!documentCountry) errorMessages.push(`${t('identity.doc.country.label')} ${t('accept-modal.missing.message')}`)
  if(!documentType) errorMessages.push(`${t('identity.doc.type.label')} ${t('accept-modal.missing.message')}`)
  if(!!extraDetails?.length && !extraDetail) {
    errorMessages.push(`${t('identity.doc.extra.label')} ${t('accept-modal.missing.message')}`)
  }
  if(fieldNames && fields) {
    fieldNames.forEach((field) => {
      const fieldData = fields[field as keyof TRequiredFields];
      zones?.forEach((zone: EZones) => {
        const { value, optional, disabled, editable } = fieldData?.[zone] || {};
        if (!optional && !value && !disabled && editable) {
          errorMessages.push(
            `${t(REGULAR_FIELDS[field]?.title)} (${t(`identity.doc.data.${zone}`)})
            ${t('accept-modal.missing.message')}`
          );
        }
      });
    })
  }
  if(documentValidationReview?.[0].result !== EAnswers.yes) {
    errorMessages.push(`${t('identity.doc.heading')} ${t('accept-modal-answer.message')}`);
  }
  if(transactionModules.faceVerification && faceVerificationReview?.result !== EAnswers.yes) {
    errorMessages.push(`${t('identity.fv.heading')} ${t('accept-modal-answer.message')}`);
  }
  if(transactionModules.livenessDetection && livenessDetectionReview?.result !== EAnswers.yes) {
    errorMessages.push(`${t('identity.ld.heading')} ${t('accept-modal-answer.message')}`);
  }
  if(documentValidationReview?.[0].result === EAnswers.yes &&
    [documentValid, documentPresented, documentPhysicalSigns, documentClearlyVisible].some((value) => !value)
  ) {
    errorMessages.push(`${t('identity.doc.heading')} ${t('accept-modal.checkboxes.message')}`);
  }
  if(transactionModules.faceVerification && faceVerificationReview?.result === EAnswers.yes &&
    [faceVerificationSamePerson, faceVerificationPhysicalSigns].some((value) => !value)
  ) {
    errorMessages.push(`${t('identity.fv.heading')} ${t('accept-modal.checkboxes.message')}`);
  }
  if(transactionModules.livenessDetection
    && livenessDetectionReview?.result === EAnswers.yes && !livenessRealVideo
  ) {
    errorMessages.push(`${t('identity.ld.heading')} ${t('accept-modal.checkboxes.message')}`);
  }
  if (transactionModules.addressCheck && addressReview?.result !== EAnswers.yes) {
    errorMessages.push(`${t('address.heading')} ${t('accept-modal-answer.message')}`);
  }
  if (transactionModules.a4Scan && a4ScanReview?.result !== EAnswers.yes) {
    errorMessages.push(`${t('a4.heading')} ${t('accept-modal-answer.message')}`);
  }
  return errorMessages;
}

const rejectionCheck = (
  t: Function, transactionModules: TTransactionModules, manualCheckResult: TManualCheckResult | undefined,
) => {
  const { addressReview, a4ScanReview, identityReview, rejectionReasons } = manualCheckResult || {};
  const { documentValidationReview, livenessDetectionReview, faceVerificationReview } = identityReview || {};
  const {imageQuality, document, documentRecording, faceVerification, livenessDetection, } = rejectionReasons || {};
  const errorMessages = [];
  const reviewResults = [documentValidationReview?.[0].result];
  if (transactionModules.faceVerification) {
    reviewResults.push(faceVerificationReview?.result);
  }
  if (transactionModules.livenessDetection) {
    reviewResults.push(livenessDetectionReview?.result);
  }
  if (transactionModules.addressCheck) {
    reviewResults.push(addressReview?.result);
  }
  if (transactionModules.a4Scan) {
    reviewResults.push(a4ScanReview?.result);
  }
  if (reviewResults.every((result: string | undefined) => result !== EAnswers.no)) {
    errorMessages.push(t('accept-modal-rejection.message'));
  }
  if(documentValidationReview?.[0].result === EAnswers.no &&
    [imageQuality, document, documentRecording].every((response) => !response?.length)
  ) {
    errorMessages.push(t('accept-modal.rejection-reason.message'));
  }
  if(transactionModules.faceVerification
    && faceVerificationReview?.result === EAnswers.no && !faceVerification?.length
  ) {
    errorMessages.push(t('accept-modal.rejection-reason.message'));
  }
  if(transactionModules.livenessDetection
    && livenessDetectionReview?.result === EAnswers.no && !livenessDetection?.length
  ) {
    errorMessages.push(t('accept-modal.rejection-reason.message'));
  }
  if (transactionModules.addressCheck
    && addressReview?.result === EAnswers.no && !rejectionReasons?.addressReview?.length) {
    errorMessages.push(t('accept-modal.rejection-reason.message'));
  }
  if (transactionModules.a4Scan
    && a4ScanReview?.result === EAnswers.no && !rejectionReasons?.a4ScanReview?.length) {
    errorMessages.push(t('accept-modal.rejection-reason.message'));
  }
  return errorMessages;
}

export {
  generateCheckResult,
  acceptanceCheck,
  rejectionCheck,
};