import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as Plus } from 'images/plus-circle.svg';
import { ReactComponent as Minus } from 'images/minus-circle.svg';
import SelectOption from 'components/select/SelectOption';
import DatePicker from 'components/datePicker/DatePicker';
import { ISelectOption } from 'types';
import { IRootState } from 'store/types';
import { EDrivingCategories, EDrivingCategoryFields, EZones } from 'enums';
import { dateIsValid } from 'helper/validator';
import { TDrivingCategory } from 'store/features/reviewResult/types';
import * as reviewResultActions from 'store/features/reviewResult/actions';

const customStyles = {
  noOptionsMessage: (base: any, state: any) => ({
    boxSizing: base.boxSizing,
    textAlign: base.padding.textAlign,
    padding: base.padding,
    color: `${state.theme.colors.neutral40}`,
    '&:hover': {
      background: `${state.theme.colors.primary25}`,
    },
  }),
  option: (base: any, state: any) => ({
    ...base,
    '&:hover': {
      backgroundColor: state.isFocused ? '#e0e0e0' : 'none',
      cursor: 'pointer',
    },
  }),
  container: (provided: any) => ({
    ...provided,
    width: '90%',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: '#d0cccc',
    '&:hover': {
      color: '#d0cccc',
    },
    pointerEvents: 'none',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    pointerEvents: 'none',
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    pointerEvents: 'none',
    display: 'none',
    backgroundColor: 'unset',
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    height: '100%',
    display: 'none',
    pointerEvents: 'none',
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: '0',
  }),
  menuList: (base: any) => ({
    ...base,
    borderRadius: '0',
  }),
  control: (base: any) => ({
    ...base,
    minHeight: '100%',
    borderRadius: '0',
    boxShadow: 'none',
    border: '1px solid #d0cccc',
  }),
};

const SelectCategory = (props: {
  currentValue: string;
  handler: Function;
  index: number;
  licenses: TDrivingCategory[];
}) => {
  const { currentValue, handler, index, licenses } = props;

  const uniqueValues = Object.values(EDrivingCategories).filter(
    (c) => !licenses.map((dl) => dl.value.value).includes(c)
  );

  const dlCategoryProps: ISelectOption = {
    value: {
      label: currentValue,
      value: currentValue,
    },
    handler: (item: ISelectOption) => handler('value', item.value, index),
    placeholder: currentValue,
    options: uniqueValues.map((item: string) => ({
      label: item,
      value: item,
    })),
    isSearchable: true,
    classNamePrefix: 'dl-item',
    className: 'valid',
    customStylesSelect: { customStyles },
  };
  return <SelectOption {...dlCategoryProps} />;
};

const newRow = (licenses?: TDrivingCategory[]) => {
  return {
    value: {
      value: '',
      extractedValue: '',
      zone: EZones.viz,
      optional: licenses?.[0]?.value?.optional || false,
    },
    issuingDate: {
      value: '',
      extractedValue: '',
      zone: EZones.viz,
      optional: licenses?.[0]?.issuingDate?.optional || false,
    },
    expiryDate: {
      value: '',
      extractedValue: '',
      zone: EZones.viz,
      optional: licenses?.[0]?.expiryDate?.optional || false,
    },
    restrictions: {
      value: '',
      extractedValue: '',
      zone: EZones.viz,
      optional: licenses?.[0]?.restrictions?.optional || false,
    },
  };
};

const DrivingField = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);

  const { manualCheckResult } = state.reviewResult;
  const { identityReview } = manualCheckResult || {};
  const { documentValidationReview } = identityReview || {};
  const [{ drivingCategory }] = documentValidationReview || [];
  const [licenses, setLicenses] = useState<TDrivingCategory[]>(drivingCategory?.length ? drivingCategory : [newRow()]);

  const handleAddRow = () => {
    const newLicenses = [
      ...licenses,
      newRow(licenses)
    ];
    dispatch(reviewResultActions.setDocumentValidation({ drivingCategory: newLicenses }));
    setLicenses(newLicenses);
  };

  const handleRemoveRow = (index: number) => {
    const updatedLicenses = licenses?.filter((_, i) => i !== index);
    if (!updatedLicenses.length) updatedLicenses.push(newRow());
    dispatch(reviewResultActions.setDocumentValidation({ drivingCategory: updatedLicenses }));
    setLicenses(updatedLicenses);
  };

  const saveFiledValue = (
    dlField: string,
    selectedValue: string,
    index: number
  ) => {
    const updateDLValue = licenses?.reduce(
      (acc: TDrivingCategory[], element: any, rowIndex: number) => {
        if (rowIndex === index) {
          acc.push({
            ...element,
            ...{
              [dlField]: {
                value: selectedValue,
                extractedValue: element[dlField].extractedValue,
                optional: element[dlField].optional,
                restrictions: element[dlField].restrictions,
              },
            },
          });
        } else {
          acc.push(element);
        }
        return acc;
      },
      []
    );
    if (updateDLValue?.length) {
      dispatch(reviewResultActions.setDocumentValidation({ drivingCategory: updateDLValue }));
      setLicenses(updateDLValue);
    }
  };
  return (
    <div>
      <div className='driving-license-form'>
        <p>{t('identity.doc.data.driving-category')}</p>
        <div className='driving-license-form-heading'>
          <span className='field-number'>9.</span>
          <span className='field-number'>10.</span>
          <span className='field-number'>11.</span>
          <span className='field-number'>12.</span>
        </div>
        <div className='driving-license-form-container'>
          {licenses?.map((license: TDrivingCategory, index: number) => (
            <div className='driving-license-form-row' key={index}>
              <div className='select-container-driving-fields'>
                <SelectCategory
                  currentValue={license.value.value}
                  handler={saveFiledValue}
                  index={index}
                  licenses={licenses}
                />
              </div>
              <DatePicker
                fieldValue={license.issuingDate.value}
                setFieldValue={(value: string) => saveFiledValue(EDrivingCategoryFields.issuingDate, value, index)}
                optional={license.issuingDate.optional}
                validated={(!license.issuingDate.value && license.issuingDate.optional)
                  || dateIsValid(license.issuingDate.value)}
                key={`${EDrivingCategoryFields.issuingDate}_${index}`}
              />
              <DatePicker
                fieldValue={license.expiryDate.value}
                setFieldValue={(date: string) => saveFiledValue(EDrivingCategoryFields.expiryDate, date, index)}
                optional={license.expiryDate.optional}
                validated={(!license.expiryDate.value && license.expiryDate.optional)
                  || dateIsValid(license.expiryDate.value)}
              />
              <input
                type='text'
                value={license.restrictions.value}
                placeholder={t('identity.doc.data.restrictions')}
                className='restrictions'
                onChange={(e) =>
                  saveFiledValue(EDrivingCategoryFields.restrictions, e.target.value, index)
                }
              />
              <button
                className='remove-row'
                onClick={() => handleRemoveRow(index)}
              >
                <Minus />
              </button>
            </div>
          ))}
          <button className='add-row' onClick={handleAddRow}>
            <Plus />
          </button>
        </div>
      </div>
    </div>
  );
};

export default DrivingField;
