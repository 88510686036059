import React, { useState } from 'react';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ReactComponent as CopyLogo } from 'images/copy.svg';
import { ReactComponent as CloseLogo } from 'images/close.svg';
import { DATE_TIME_FORMAT, KEY_CODE } from 'constants/Static';
import { ERoutePaths } from 'enums';
import { IRootState } from 'store/types';
import { TTab } from 'types';
import { convertTimestampToDate, getAllowedTabs } from 'helper';
import { UnlockTransactionAPI } from 'helper/api/route';

const showMessageTimeout = 1000;

const copyCode = (code: string, toggle: Function) => {
  copy(code);
  toggle(true);
  setTimeout(() => toggle(false), showMessageTimeout);
};

const InfoBar = (props: { unlockTransaction?: boolean, reviewEndTimes?: Array<number> }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { unlockTransaction = true, reviewEndTimes } = props;
  const { transactionCode = '' } = useParams();
  const state = useSelector((rootState: IRootState) => rootState);
  const { transactionData } = state.reviewResult;
  const [isMessageShow, toggleMessage] = useState(false);

  const enterPressed = (event: React.KeyboardEvent) => {
    const code = event.keyCode || event.which;
    if (code === KEY_CODE.ENTER) {
      copyCode(transactionCode, toggleMessage);
    }
  };

  const getActiveTab = (tab: TTab) => {
    return location.pathname.includes(tab.url);
  };

  const handleTransactionClose = () => {
    unlockTransaction && UnlockTransactionAPI(transactionCode);
    navigate(ERoutePaths.dashboard);
  };

  return (
    <div className="info-bar">
      <div className="info-bar-item">
        <p className="into_bar_text">
          {t('review-header.tc.label')}
          <span className="info_bar_tr_code">
            {' '}
            {transactionCode}
          </span>
        </p>
        <div
          className="copy-button"
          role="button"
        >
          <CopyLogo
            onKeyDown={(e) => enterPressed(e)}
            onClick={() => { copyCode(transactionCode, toggleMessage); }}
            tabIndex={5}
          />
          <div className={`${isMessageShow ? 'reg-tooltip' : 'reg-tooltip-hide'}`}>
            <span>
              {t('review-header.copied')}
              {' '}
              {transactionCode}
            </span>
          </div>
        </div>
      </div>
      <div className="tab-container">
        {!location.pathname.includes(ERoutePaths.taBoarding) ?
          reviewEndTimes?.length ? reviewEndTimes.map((item: number) => (
            <button
              key={item}
              className={`tab-button ${location.pathname.includes(String(item)) ? 'active' : ''}`}
              onClick={() => navigate(`${ERoutePaths.summary}/${transactionCode}/${transactionCode}${item}`)}
            > {convertTimestampToDate(item, DATE_TIME_FORMAT)}
            </button>
          )) :
            getAllowedTabs(transactionData.transactionModules).map((tab) => (
              <button
              key={tab.name}
              className={`tab-button ${getActiveTab(tab) ? 'active' : ''}`}
              onClick={() => navigate(`${tab.url}/${transactionCode}`)}
              >
                {t(tab.name)}
              </button>
            )) : null
        }
      </div>
      <div className="info-bar-item status">
        <div
          className="button"
          role="button"
        >
          <CloseLogo
            tabIndex={6}
            onClick={handleTransactionClose}
            onKeyPress={handleTransactionClose}
          />
        </div>
      </div>
    </div>
  );
};

export default InfoBar;
