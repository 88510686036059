import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReactComponent as Search } from 'images/search.svg';
import { ReactComponent as Switch } from 'images/redirect.svg';
import { ReactComponent as RefreshButtonIcon } from 'images/refresh.svg';
import Loader from 'components/loader/Loader';
import { IRootState } from 'store/types';
import { getSelectedDataset } from 'helper';
import SingleButton from 'components/buttonTypes/SingleButton';
import { BUTTON_STYLE } from 'constants/Static';
import { ESearchType } from 'enums';
import { TLockTransaction } from 'types';

const LockTransaction = (props: {
  setSearchModalIsShowing: Function;
  setSwitchModalIsShowing: Function;
  setSearchType: Function;
  isWaiting: boolean;
  onRefresh: Function;
  startReview: (options?: TLockTransaction) => void;
}) => {
  const { t } = useTranslation();
  const state = useSelector((rootState: IRootState) => rootState);
  const { datasets = [] } = state?.agentProfile?.agent || {};
  const {
    setSearchModalIsShowing,
    setSwitchModalIsShowing,
    setSearchType,
    isWaiting,
    onRefresh,
    startReview,
  } = props;
  const dataset = useMemo(() => getSelectedDataset(datasets), [datasets]);
  const loading = state?.common?.loading;
  const invalidDataset = !dataset?.id || !dataset.accounts.length;

  const handleSearchByType = (searchType: ESearchType) => {
    setSearchModalIsShowing(true);
    setSearchType(searchType);
  };

  return (
    <div className="new-transactions-container">
      <div className="title">
        <h2>{t('dashboard.heading')}</h2>
      </div>
      <div className="new-transaction-content">
        <div className="app-brand-container">
          <div className="new-transactions-text">
            <h5>{t('dashboard.dataset-name.label')}</h5>
            <span>{dataset?.name || '-'}</span>
          </div>
          {
            datasets.length > 1 ? <div
              className="new-transactions-switch-button"
              role="button"
              tabIndex={0}
              onClick={() => setSwitchModalIsShowing(true)}
            >
              <Switch className="new-transactions-icon" />
              <div className="action-button-title">{t('dashboard.switch-dataset.btn')}</div>
            </div> : <></>
          }
        </div>
        <div className="button-container">
          <SingleButton
            isActive={!invalidDataset}
            onClick={() => startReview()}
            textButton={'dashboard.start-review.btn'}
            sizeButton={BUTTON_STYLE.BIG}
            isDisabled={isWaiting || invalidDataset}
            radiusButton={BUTTON_STYLE.CIRCLE_BUTTON}
            variant={BUTTON_STYLE.OUTLINE_SECONDARY}
          />
          <div className="ta-new-transaction-loader-container">
            {isWaiting && (<Loader />)}
          </div>
        </div>
        <div className="refresh-button-container">
          <SingleButton
            onClick={onRefresh}
            textButton={'dashboard.refresh.btn'}
            sizeButton={BUTTON_STYLE.BIG}
            isDisabled={loading || invalidDataset}
            radiusButton={BUTTON_STYLE.CIRCLE_BUTTON}
            variant={BUTTON_STYLE.OUTLINE_PRIMARY}
            LeftIcon={RefreshButtonIcon}
          />
        </div>
        <div className="action-button-container">
          <div
            className="new-transactions-search-button"
            role="button"
            tabIndex={0}
            onClick={() => handleSearchByType(ESearchType.transactionCode)}
          >
            <Search className="new-transactions-icon" />
            <div className="action-button-title">{t('dashboard.search-tc.btn')}</div>
          </div>
          <div
            className="new-transactions-search-button"
            role="button"
            tabIndex={0}
            onClick={() => handleSearchByType(ESearchType.orderId)}
          >
            <Search className="new-transactions-icon" />
            <div className="action-button-title">{t('dashboard.search-oi.btn')}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LockTransaction;
