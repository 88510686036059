import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { EFileTypes } from 'enums';
import { IRootState } from 'store/types';
import Modal from 'components/modal/Modal';
import ImageWrapper from 'components/imageWrapper/ImageWrapper';
import AccordionField from 'components/accordionField/AccordionField';
import AddressImages from 'pages/addressReview/addressImages/AddressImages';
import AddressData from 'pages/addressReview/addressData/AddressData';

const AddressReview = () => {
  const { t } = useTranslation();
  const { transactionCode, transactionData } = useSelector((state: IRootState) => state.reviewResult);
  const pageEnabled = transactionData.transactionModules.addressCheck;
  const [filename, setFilename] = useState('');

  const content = useMemo(() => {
    return (
      <div className='address-main-content'>
        <div className='address-images-content'>
          <AddressImages setFilename={setFilename} />
        </div>
        <div className='address-data-content'>
          <AddressData />
        </div>
      </div>
    );
  }, [transactionCode]);

  return (<>
    <div className='address-review-summary-content'>
      <AccordionField
        title={t('address.heading')}
        body={content}
        className={'accordion-container'}
        showStatus={pageEnabled}
        eventKey={1}
        preventToggle={!pageEnabled}
      />
      {!!filename && (
        <Modal show onHide={() => setFilename('')}>
          <div className='address-image-wrapper-container'>
            <ImageWrapper
              transactionCode={transactionCode}
              filetype={EFileTypes.addressDocument}
              filename={filename}
              enableZoom
              original
            />
          </div>
        </Modal>
      )}
    </div>
  </>);
};

export default AddressReview;
