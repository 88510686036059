import { TFunction } from 'react-i18next';

import { EFields, EValidationTypes, EComponentTypes } from 'enums';
import Nationalities from 'constants/Nationalities.json';
import DocumentTypes from 'constants/DocumentTypes.json';
import GenderOptions from 'constants/GenderOptions.json';
import Countries from 'constants/Countries.json';
import { IOptionType, ISpecimen, TFieldsData } from 'types';
import { mapCountryOptions, mapOptions } from 'pages/identityReview/helper';
import { collectExtraDetailOptions } from 'helper';

export const REGULAR_FIELDS: TFieldsData = {
  [EFields.firstName]: {
    validationType: EValidationTypes.utf8Extended,
    componentType: EComponentTypes.input,
    title: 'identity.doc.data.first-name',
    min: 1,
    max: 50,
  },
  [EFields.lastName]: {
    validationType: EValidationTypes.utf8Extended,
    componentType: EComponentTypes.input,
    title: 'identity.doc.data.last-name',
    min: 1,
    max: 50,
  },
  [EFields.maidenName]: {
    validationType: EValidationTypes.utf8Extended,
    componentType: EComponentTypes.input,
    title: 'identity.doc.data.maiden-name',
    min: 1,
    max: 50,
  },
  [EFields.marriedName]: {
    validationType: EValidationTypes.utf8Extended,
    componentType: EComponentTypes.input,
    title: 'identity.doc.data.married-name',
    min: 1,
    max: 50,
  },
  [EFields.fullName]: {
    validationType: EValidationTypes.utf8Extended,
    componentType: EComponentTypes.input,
    title: 'identity.doc.data.full-name',
    min: 1,
    max: 100,
  },
  [EFields.documentNumber]: {
    validationType: EValidationTypes.utf8,
    componentType: EComponentTypes.input,
    title: 'identity.doc.data.document-number',
    min: 1,
    max: 20,
  },
  [EFields.zemisNumber]: {
    validationType: EValidationTypes.zemisNumber,
    componentType: EComponentTypes.input,
    title: 'identity.doc.data.zemis-number',
    min: 1,
    max: 10,
  },
  [EFields.gender]: {
    validationType: EValidationTypes.gender,
    componentType: EComponentTypes.dropDown,
    getOptions: (t: TFunction) => mapOptions(GenderOptions, t),
    title: 'identity.doc.data.gender',
    min: 1,
    max: 1,
  },
  [EFields.dateOfBirth]: {
    validationType: EValidationTypes.date,
    componentType: EComponentTypes.datepicker,
    title: 'identity.doc.data.date-of-birth',
    min: 10,
    max: 10,
  },
  [EFields.expirationDate]: {
    validationType: EValidationTypes.date,
    componentType: EComponentTypes.datepicker,
    title: 'identity.doc.data.expiration-date',
    min: 10,
    max: 10,
  },
  [EFields.dateOfIssue]: {
    validationType: EValidationTypes.date,
    componentType: EComponentTypes.datepicker,
    title: 'identity.doc.data.date-of-issue',
    min: 10,
    max: 10,
  },
  [EFields.dateOfEntry]: {
    validationType: EValidationTypes.date,
    componentType: EComponentTypes.datepicker,
    title: 'identity.doc.data.date-of-entry',
    min: 10,
    max: 10,
  },
  [EFields.documentType]: {
    validationType: EValidationTypes.default,
    componentType: EComponentTypes.dropDown,
    getOptions: (t: TFunction) => mapOptions(DocumentTypes, t),
    getPlaceholder: (value: string, selectOptions: IOptionType[] | undefined) => (
      selectOptions?.find((option) => option.value === value)?.label || value
    ),
    title: 'identity.doc.data.document-type',
    min: 1,
    max: 50,
  },
  [EFields.documentSubtype]: {
    validationType: EValidationTypes.default,
    componentType: EComponentTypes.dropDown,
    getOptions: (t: TFunction, specimens?: ISpecimen[]) => collectExtraDetailOptions(specimens || [], t),
    title: 'identity.doc.data.document-subtype',
    min: 1,
    max: 50,
  },
  [EFields.documentCountry]: {
    validationType: EValidationTypes.default,
    componentType: EComponentTypes.dropDown,
    getOptions: (t: TFunction) => mapCountryOptions(Countries, t),
    title: 'identity.doc.data.document-country',
    min: 1,
    max: 100,
  },
  [EFields.nationality]:{
    validationType: EValidationTypes.default,
    componentType: EComponentTypes.dropDown,
    getOptions: (t: TFunction) => mapCountryOptions(Countries.concat(Nationalities), t),
    title: 'identity.doc.data.nationality',
    min: 1,
    max: 100,
  },
  [EFields.placeOfBirth]: {
    validationType: EValidationTypes.utf8,
    componentType: EComponentTypes.input,
    title: 'identity.doc.data.place-of-birth',
    min: 1,
    max: 100,
  },
  [EFields.overallRestriction]: {
    validationType: EValidationTypes.utf8,
    componentType: EComponentTypes.input,
    title: 'identity.doc.data.overall-restriction',
    min: 1,
    max: 50,
  },
  [EFields.endorsements]: {
    validationType: EValidationTypes.utf8,
    componentType: EComponentTypes.input,
    title: 'identity.doc.data.endorsements',
    min: 1,
    max: 50,
  },
  [EFields.drivingCategories]: {
    validationType: EValidationTypes.utf8,
    componentType: EComponentTypes.input,
    title: 'identity.doc.data.driving-categories',
    min: 1,
    max: 50,
  },
  [EFields.authority]: {
    validationType: EValidationTypes.utf8Extended,
    componentType: EComponentTypes.input,
    title: 'identity.doc.data.authority',
    min: 1,
    max: 50,
  },
  [EFields.address]: {
    validationType: EValidationTypes.utf8,
    componentType: EComponentTypes.input,
    title: 'identity.doc.data.address',
    min: 1,
    max: 256,
  },
  [EFields.kantonReferenceNumber]: {
    validationType: EValidationTypes.kantonReferenceNumber,
    componentType: EComponentTypes.input,
    title: 'identity.doc.data.kanton-reference-number',
    min: 1,
    max: 20,
  },
  [EFields.personalIdentificationNumber]: {
    validationType: EValidationTypes.utf8,
    componentType: EComponentTypes.input,
    title: 'identity.doc.data.personal-identification-number',
    min: 1,
    max: 50,
  },
  [EFields.institutionNumber]: {
    validationType: EValidationTypes.utf8,
    componentType: EComponentTypes.input,
    title: 'identity.doc.data.institution-number',
    min: 1,
    max: 50,
  },
  [EFields.restrictions]: {
    validationType: EValidationTypes.utf8,
    componentType: EComponentTypes.input,
    title: 'identity.doc.data.restrictions',
    min: 1,
    max: 50,
  }
}
