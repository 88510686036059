import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as Back } from 'images/back.svg';
import { ReactComponent as Next } from 'images/next.svg';
import { IRootState } from 'store/types';
import { EDirection, EFileTypes } from 'enums';
import { BUTTON_STYLE, BUTTON_TYPE } from 'constants/Static';
import ButtonTypes from 'components/buttonTypes/ButtonTypes';
import { IAnswerOption, ISpecimen } from 'types';
import * as reviewResultActions from 'store/features/reviewResult/actions';
import { SendEmailAPI } from 'helper/api/route';
import Countries from 'constants/Countries.json';
import DocumentTypes from 'constants/DocumentTypes.json';
import ImageWrapper from 'components/imageWrapper/ImageWrapper';

const SLIDER_SIZE = 4;
const ANSWERS_KEYS = {
  SELECT: 'select',
  NOT_FOUND: 'not-found',
};

const ANSWERS = [
  {
    text: 'specimen.btn.not-found',
    buttonType: BUTTON_TYPE.OPTION_BUTTON,
    key: ANSWERS_KEYS.NOT_FOUND,
  },
  {
    text: 'specimen.btn.select',
    buttonType: BUTTON_TYPE.OPTION_BUTTON,
    key: ANSWERS_KEYS.SELECT,
  }
];

interface ISpecimenProps {
  firstImage: string;
  secondImage: string;
  onHide: (value: boolean) => void;
  specimens: ISpecimen[];
}

const Specimen = (props: ISpecimenProps) => {
  const { firstImage, secondImage, onHide, specimens } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const { manualCheckResult, transactionCode } = state.reviewResult;
  const { email = '' } = state.agentProfile?.agent || {};
  const { identityReview } = manualCheckResult;
  const { documentValidationReview } = identityReview;
  const [{ documentType, documentCountry, duid: storedDuid }] = documentValidationReview;
  const [duid, setDuid] = useState(storedDuid);
  const currentIndex = Math.max(0, specimens.findIndex((item) => item.duid === duid));
  const [sliderTab, setSliderTab] = useState(Math.floor(currentIndex / SLIDER_SIZE) + 1);
  const currentSpecimen = useMemo(() => specimens.find((specimen) => specimen?.duid === duid) , [duid]);

  useEffect(() => {
    setSliderTab(Math.floor(currentIndex / SLIDER_SIZE) + 1);
  }, [currentIndex]);

  const changeSpecimen = (direction: EDirection) => {
    const index = currentIndex + direction < 0
      ? specimens.length - 1 : (currentIndex + direction) % specimens.length;
    setDuid(specimens[index].duid);
  };

  const handleSpecimenSelection = (answer: IAnswerOption) => {
    setDuid(answer.key);
  };

  const specimenAnswers = specimens.map((item: ISpecimen, index: number) => ({
    text: item.duid?.split('-')[2] || '',
    key: item?.duid || '',
    buttonType:
      specimens.length === 1
        ? BUTTON_TYPE.OPTION_BUTTON
        : BUTTON_TYPE.RADIO_BUTTON,
    radius:
      specimens.length === 1
        ? BUTTON_STYLE.CIRCLE_BUTTON
        : index === 0
          ? BUTTON_STYLE.CIRCLE_BUTTON_START
          : index === specimens.length - 1
            ? BUTTON_STYLE.CIRCLE_BUTTON_END
            : BUTTON_STYLE.CIRCLE_BUTTON,
  }));
  const tabSize = Math.ceil(specimenAnswers.length / SLIDER_SIZE);
  const startIndex =
    sliderTab === tabSize
      ? specimenAnswers.length - SLIDER_SIZE
      : (sliderTab - 1) * SLIDER_SIZE;

  const filteredAnswers =
    specimenAnswers.length <= SLIDER_SIZE
      ? specimenAnswers
      : specimenAnswers.splice(startIndex, SLIDER_SIZE);

  const selectionAnswers = specimens.length ? ANSWERS : [ANSWERS[0]];

  const handleClick = (answer: IAnswerOption) => {
    if (answer.key === ANSWERS_KEYS.NOT_FOUND) {
      dispatch(reviewResultActions.setDocumentValidation({ duid: '', specimenNotFound: true }));
      const docCountry = Countries.find((option) => option.value === documentCountry);
      const docType = DocumentTypes.find((option) => option.value === documentType);
      if (process.env.REACT_APP_SEND_MISSING_SPECIMEN_EMAIL !== 'true') {
        SendEmailAPI({
          country: t(docCountry?.label || ''),
          docType: t(docType?.label || ''),
          trCode: transactionCode,
          agentEmail: email,
        });
      }
    } else if (answer.key === ANSWERS_KEYS.SELECT) {
      dispatch(reviewResultActions.setDocumentValidation({ duid, specimenNotFound: false }));
    }
    onHide(true);
  };

  return (
    <div className='template-container'>
      <div className='document-template-image-container'>
        <div className='image-container-doc'>
          <div className='images-container'>
            <div className='image-item'>
              <ImageWrapper
                transactionCode={transactionCode}
                filetype={EFileTypes.firstImage}
                filename={firstImage}
              />
            </div>
            <div className='image-item'>
              <ImageWrapper
                transactionCode={transactionCode}
                filetype={EFileTypes.secondImage}
                filename={secondImage}
              />
            </div>
          </div>
        </div>
        <div className='label-container'>
          {t('specimen.doc.sub-heading')}
        </div>
      </div>
      <div className='document-template-image-container'>
        <div className='image-container-doc'>
          {specimens.length > 1 && (
            <div
              className='left-arrow arrow-container'
              role='button'
              onClick={() => changeSpecimen(EDirection.left)}
              tabIndex={0}
            >
              <Back />
            </div>
          )}
          <div className='images-container'>
            <div className='image-item'>
              <ImageWrapper
                transactionCode={transactionCode}
                specimenImage={currentSpecimen?.front || ''}
                filetype={EFileTypes.firstImage}
              />
            </div>
            <div className='image-item'>
              <ImageWrapper
                transactionCode={transactionCode}
                specimenImage={currentSpecimen?.back || ''}
                filetype={EFileTypes.secondImage}
              />
            </div>
          </div>
          {specimens.length > 1 && (
            <div
              className='right-arrow arrow-container'
              role='button'
              onClick={() => changeSpecimen(EDirection.right)}
              tabIndex={0}
            >
              <Next />
            </div>
          )}
        </div>
        <div className='label-container'>{t('specimen.sub-heading')}</div>
      </div>
      <h5 className='specimen-message-content'>{t('specimen.message')}</h5>
      <div className='main-buttons'>
        {specimens.length > SLIDER_SIZE && (
          <div
            className={`arrow-container ${sliderTab === 1 ? 'disabled' : ''}`}
            role='button'
            onClick={() => sliderTab > 1 && setSliderTab(sliderTab + EDirection.left)}
          >
            <Back />
          </div>
        )}
        {!!specimens.length && (
          <ButtonTypes
            answers={filteredAnswers}
            styled={BUTTON_STYLE.OUTLINE_SECONDARY}
            activeIndexes={[filteredAnswers.findIndex(({ key }) => key === duid )]}
            handleClick={handleSpecimenSelection}
          />
        )}
        {specimens.length > SLIDER_SIZE && (
          <div
            className={`arrow-container ${sliderTab === tabSize ? 'disabled' : '' }`}
            role='button'
            onClick={() => sliderTab < tabSize && setSliderTab(sliderTab + EDirection.right)}
          >
            <Next />
          </div>
        )}
        <ButtonTypes
          answers={selectionAnswers}
          styled={BUTTON_STYLE.OUTLINE_SECONDARY}
          activeIndexes={[(selectionAnswers || [])?.length - 1]}
          handleClick={handleClick}
        />
      </div>
    </div>
  );
};

export default Specimen;
