import moment from 'moment';
import i18n from 'i18next';
import { z, ZodSchema } from 'zod';

import { EFields, EValidationTypes } from 'enums';
import { REGULAR_FIELDS } from 'constants/Fields';
import { DATE_FORMAT, DEFAULT_MAX_SIZE, DEFAULT_MIN_SIZE } from 'constants/Static';

const ERROR_MESSAGE = i18n.t('Invalid value');

const dateIsValid = (date: string) => moment(date, DATE_FORMAT, true).isValid();

const getZodSchema = (field: EFields) => {
  const data = REGULAR_FIELDS[field];
  switch (data?.validationType) {
  case EValidationTypes.utf8:
    return z
      .string()
      .regex(/^[\p{Letter}\p{Mark}\p{Number}\s-]*$/gu, ERROR_MESSAGE)
  case EValidationTypes.utf8Extended:
    return z
      .string()
      .min(data.min, ERROR_MESSAGE)
      .max(data.max, ERROR_MESSAGE)
      .regex(/^[\p{Letter}\p{Mark}'()*+,-./ ]*$/gu, ERROR_MESSAGE);
  case EValidationTypes.date:
    return z
      .string()
      .refine((date) => dateIsValid(date), {
        message: 'Invalid date value',
      });
  case EValidationTypes.zemisNumber:
    return z
      .string()
      .max(data.max, ERROR_MESSAGE)
      .regex(/^[0-9.]*$/, ERROR_MESSAGE);
  case EValidationTypes.gender:
    return z
      .string()
      .regex(/^m$|^f$|^-$/, ERROR_MESSAGE);
  case EValidationTypes.kantonReferenceNumber:
    return z
      .string()
      .max(data.max, ERROR_MESSAGE)
      .regex(/^[A-Za-z0-9-./ ]*$/, ERROR_MESSAGE);
  default:
    return z
      .string()
      .min(data?.min || DEFAULT_MIN_SIZE, ERROR_MESSAGE)
      .max(data?.max || DEFAULT_MAX_SIZE, ERROR_MESSAGE);
  }
}

const fieldValueValidated = (field: EFields, value: string) => {
  try {
    (getZodSchema(field) as ZodSchema<string>).parse(value);
    return true;
  } catch (error) {
    return false;
  }
};

const isValidEmail = (email = '') => !!(email && email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/));

const getPasswordRequirements = (password: string) => {
  return [
    {
      // Minimum length: 12
      message: 'set-password.message.option-1',
      valid: password && password.length >= 12,
    },
    {
      // Maximum length: 100
      message: 'set-password.message.option-2',
      valid: password && password.length <= 100,
    },
    {
      // Upper case: min 1
      message: 'set-password.message.option-3',
      valid: password && /[A-Z]/.test(password),
    },
    {
      // Lower case: min 1
      message: 'set-password.message.option-4',
      valid: password && /[a-z]/.test(password),
    },
    {
      // Digits: min 2
      message: 'set-password.message.option-5',
      valid: password && /(\D*\d\D*){2}/.test(password),
    },
    {
      // No spaces allowed
      message: 'set-password.message.option-6',
      valid: password && /^\S*$/.test(password),
    },
  ];
}

export {
  dateIsValid,
  fieldValueValidated,
  isValidEmail,
  getPasswordRequirements,
}