import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  A4ScanReviewRejectionReasons,
  AddressReviewRejectionReasons,
  DocumentRejectionReasons,
  FaceVerificationRejectionReasons,
  ImageQualityRejectionReasons,
  LivenessRejectionReasons,
  ExternalVerificationRejectIonReasons,
  RecordRejectionReasons
} from 'constants/Reasons';
import { EReviewStates, EStatuses } from 'enums';
import { IRootState } from 'store/types';
import { convertTimestampToDate } from 'helper';
import { getReturnCodeKeys } from 'helper/extractedDataHandling';
import { TManualReview } from 'store/features/reviewResult/types';
import InputField from 'components/input/Input';
import TextAreaField from 'components/textArea/textArea';
import { DATE_TIME_FORMAT } from 'constants/Static';

const reviewClassMap: { [key: string]: string } = {
  [EReviewStates.ACCEPTED]: EStatuses.pass,
  [EReviewStates.REJECTED]: EStatuses.fail,
};

const userInputClassName = (value: string) => reviewClassMap[value] || '';

const allReasons = [
  ...RecordRejectionReasons,
  ...DocumentRejectionReasons,
  ...LivenessRejectionReasons,
  ...ImageQualityRejectionReasons,
  ...FaceVerificationRejectionReasons,
  ...AddressReviewRejectionReasons,
  ...A4ScanReviewRejectionReasons,
  ...ExternalVerificationRejectIonReasons,
];

const getRejectionLabel = (value: string, reasons: Array<{value: string, label: string}>) => {
  const reason = reasons.find(reason => reason.value === value);
  return reason ? reason.label : value;
}

const TransactionSummary = () => {
  const { t } = useTranslation();
  const { reviewId = '' } = useParams();
  const { reviewResult } = useSelector((rootState: IRootState) => rootState);
  const { transactionData } = reviewResult || {};
  const { manualReview = [], returnCodes } = transactionData || {};

  const currentReview = useMemo(
    () =>
      manualReview.find(
        (review: TManualReview) => review?.history?.reviewId === reviewId
      ),
    [manualReview, reviewId]
  );

  const { history, rejectionReasons } = currentReview || {};
  const { completedTime, startReviewAt, endReviewAt, reviewedBy = '', reviewState = '', comments = '' } = history || {};

  const reviewDuration = useMemo(() => {
    if (startReviewAt && endReviewAt) {
      const durationInSecond = new Date(endReviewAt).getTime() - new Date(startReviewAt).getTime();
      const minutes = Math.floor(durationInSecond / 60);
      const remainingSeconds = durationInSecond % 60;
      return `${minutes}m ${remainingSeconds}s`;
    }
    return 0;
  }, [startReviewAt, endReviewAt]);

  const manualCheckReason = useMemo(() => {
    return getReturnCodeKeys(returnCodes).map((key: string) => {
      return `\u2022 ${t(`${key}`)}`;
    }).join('\n');
  }, [returnCodes]);

  const allRejectionReasons = Object.values(rejectionReasons ?? {}).flat();
  const rejectionReasonsLabels = useMemo(() => {
    return allRejectionReasons.map(value => {
      const label = getRejectionLabel(value, allReasons) || '';
      return `\u2022 ${t(`${label}`)}`;
    }).join('\n');
  }, [allRejectionReasons]);

  return (<>
    <div className='transaction-summary-content'>
      <h1>{t('review.heading')}</h1>
      <div className="transaction-summary-input-container">
        <div className="summary-info-container">
          <div className="summary-info-container-first-row">
            <InputField
              disabled={true}
              label={t('dashboard.reviewed-tab.timestamp1.label')}
              placeholder={convertTimestampToDate(completedTime ?? 0, DATE_TIME_FORMAT)}
            />
            <InputField
              label={t('dashboard.reviewed-tab.reviewer.label')}
              placeholder={reviewedBy}
              disabled={true}
            />
          </div>
          <div className="summary-info-container-second-row">
            <InputField
              label={t('review.started')}
              disabled={true}
              placeholder={convertTimestampToDate(startReviewAt ?? 0, DATE_TIME_FORMAT)}
            />
            <InputField
              label={t('dashboard.reviewed-tab.timestamp2.label')}
              disabled={true}
              placeholder={convertTimestampToDate(endReviewAt ?? 0, DATE_TIME_FORMAT)}
            />
            <InputField
              label={t('review.duration')}
              disabled={true}
              placeholder={`${reviewDuration}`}
            />
          </div>
        </div>
        <div className="manual-check-reasons-container">
          <TextAreaField label={t('review-header.reasons.label')} placeholder={manualCheckReason} disabled={true}/>
        </div>
      </div>
      <h1 className='outcome'>{t('review.result.title')}</h1>
      <div className="outcome-summary-input-container">
        <div className="outcome-container">
          <div className="outcome-container-first-row">
            <InputField
              label={t('dashboard.reviewed-tab.status.label')}
              placeholder={reviewState}
              disabled={true}
              userInputClassName={userInputClassName(reviewState)}
            />
          </div>
          <div className="outcome-container-second-row">
            <InputField
              label={t('dashboard.reviewed-tab.comments.label')}
              placeholder={comments || ''}
              disabled={true}
            />
          </div>
        </div>
        <div className="rejection-reasons-container">
          <TextAreaField
            label={t('review.rejection-reasons.label')}
            placeholder={rejectionReasonsLabels}
            disabled={true}
          />
        </div>
      </div>
    </div>
  </>);
};

export default TransactionSummary;