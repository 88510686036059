import {
  TTransactionData,
  ReviewResultActions,
  TRejectionReasons,
  TDocumentValidationReview,
  TAcceptanceChecks,
  TResultItem,
} from 'store/features/reviewResult/types';

export const setTransactionData = (value: {
  transactionData: TTransactionData; transactionCode: string;
}) => ({
  type: ReviewResultActions.setTransactionData,
  value,
});

export const setRejectionReasons = (value: TRejectionReasons) => ({
  type: ReviewResultActions.setRejectionReasons,
  value,
});

export const setDocumentValidation = (value: TDocumentValidationReview) => ({
  type: ReviewResultActions.setDocumentValidation,
  value,
});

export const setAcceptanceChecks = (value: TAcceptanceChecks) => ({
  type: ReviewResultActions.setAcceptanceChecks,
  value,
});

export const setA4ScanReview = (value: TResultItem) => ({
  type: ReviewResultActions.setA4ScanReview,
  value,
});

export const setAddressReview = (value: TResultItem) => ({
  type: ReviewResultActions.setAddressReview,
  value,
});

export const setFaceVerificationReview = (value: TResultItem) => ({
  type: ReviewResultActions.setFaceVerificationReview,
  value,
});

export const setLivenessDetectionReview = (value: TResultItem) => ({
  type: ReviewResultActions.setLivenessDetectionReview,
  value,
});
