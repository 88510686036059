import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { batchActions } from 'redux-batched-actions';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';

import { IRootState } from 'store/types';
import { EAcceptanceCheckNames, EAnswers, EDocumentMaterial, EDocumentType, EFileTypes } from 'enums';
import { IOptionType, ISelectOption, TGenericItem } from 'types';
import {
  collectExtraDetailOptions,
  formatRejectionReasons,
  getCountryValue,
  getDocumentTypeValue,
  getExtraDetailValue,
  getFileNames,
} from 'helper';
import { ReactComponent as Info } from 'images/info.svg';
import { ReactComponent as SpecimenPopup } from 'images/specimen_popup.svg';
import Countries from 'constants/Countries.json';
import DocumentTypes from 'constants/DocumentTypes.json';
import SelectOption from 'components/select/SelectOption';
import Comparison from 'components/comparison/Comparison';
import ImageWrapper from 'components/imageWrapper/ImageWrapper';
import VideoPlayer from 'components/videoPlayer/VideoPlayer';
import ToolTip from 'components/toolTipField/ToolTipField';
import MultipleSelect from 'components/select/MultipleSelect';
import * as reviewResultActions from 'store/features/reviewResult/actions';
import {
  RecordRejectionReasons,
  DocumentRejectionReasons,
  ImageQualityRejectionReasons,
} from 'constants/Reasons';
import { GetSpecimensAPI } from 'helper/api/route';
import DataVerification from 'pages/identityReview/documentValidation/dataVerification/DataVerification';
import { documentTypeMapping, DUID_LABEL_MAPPING } from 'constants/Static';
import { TSpecimen } from 'store/features/reviewResult/types';
import { hasActiveAcceptanceCheck } from 'pages/identityReview/helper';
import Modal from 'components/modal/Modal';
import Specimen from 'components/modal/Specimen';

type TInternalState = {
  modalShow?: boolean;
  specimens?: TSpecimen[];
  documentType?: EDocumentType;
  documentCountry?: string;
  extraDetails?: IOptionType[];
  duid?: string;
  extraDetail?: string;
  filename?: string;
  specimenNotFound?: boolean;
}

const getDUID = (
  specimens: TSpecimen[], classifiedDocument: TGenericItem | undefined, ed = ''
) => {
  const { front = '', back = '' } = classifiedDocument || {};
  if (!specimens || !specimens.length) return '';
  const detectedSpecimen = specimens.find(({ duid, extraDetail }) =>
    ((front && duid.startsWith(front)) || (back && duid.startsWith(back))) && extraDetail === ed
  );
  return detectedSpecimen?.duid || specimens?.find(({ extraDetail }) => extraDetail === ed)?.duid || '' || '';
}

const filteredSpecimens = (
  specimens: TSpecimen[], value: string
) => specimens.filter(({ extraDetail }) => extraDetail === value);

const DocumentValidation = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const { transactionCode, transactionData, manualCheckResult } = state.reviewResult;
  const { acceptanceChecks, rejectionReasons, identityReview } = manualCheckResult;
  const { documentValidationReview = [] } = identityReview;
  const [{
    result,
    classifiedDocument,
    specimenNotFound = false,
  }] = documentValidationReview;
  const [modalShow, setModalShow] = useState(false);
  const [filename, setFilename] = useState('');
  const [{
    duid = '',
    specimens = [],
    documentType = '',
    documentCountry = '',
    extraDetails = [],
    extraDetail = '',
  }] = documentValidationReview;
  const currentSpecimen = useMemo(() => specimens.find((specimen) => specimen?.duid === duid), [duid, specimens]);

  const filenames = useMemo(() => getFileNames(transactionData, [
    EFileTypes.fullFrameFirstPage, EFileTypes.fullFrameSecondPage,
    EFileTypes.signatureImage, EFileTypes.nfcSignaturePhoto,
    EFileTypes.firstImage, EFileTypes.secondImage,
    EFileTypes.firstVideo, EFileTypes.secondVideo,
  ]), [transactionCode]);

  const imageQualityReasons = useMemo(() => formatRejectionReasons(
    ImageQualityRejectionReasons, rejectionReasons.imageQuality, t,
  ), [rejectionReasons]);

  const documentReasons = useMemo(() => formatRejectionReasons(
    DocumentRejectionReasons, rejectionReasons.document, t,
  ), [rejectionReasons]);

  const documentRecordingReasons = useMemo(() => formatRejectionReasons(
    RecordRejectionReasons, rejectionReasons.documentRecording, t,
  ), [rejectionReasons]);

  const duidValidated = useCallback(() => {
    if (!duid || specimenNotFound) return true;
    const items = documentType.split('-');
    const documentMaterial = items.length > 1 ? items[0] : items[1] || '';
    const docType = (items.length > 1 ? items[1] : documentType);
    const duidDocumentType = documentTypeMapping[docType] || docType;
    return duid.startsWith(`${documentCountry.toLocaleLowerCase()}-${duidDocumentType}`) && (
      documentMaterial === EDocumentMaterial.plastic ?
        !duid.includes(EDocumentMaterial.paper) : duid.includes(documentMaterial)
    );
  }, [documentType, documentCountry, duid, specimenNotFound]);

  useEffect(() => {
    if (documentType && documentCountry) {
      const updatedData: TInternalState = {};
      GetSpecimensAPI(documentType, documentCountry.toLowerCase()).then((response) => {
        const extraDetails = collectExtraDetailOptions(response.data, t);
        const hasDetectedExtraDetail = extraDetails.some(({ value }) => value === extraDetail);
        if (extraDetails.length && !hasDetectedExtraDetail) {
          updatedData.extraDetail = '';
          updatedData.duid = '';
        } else if (!extraDetails.length) {
          updatedData.duid = getDUID(response.data, classifiedDocument);
          updatedData.extraDetail = '';
        } else if (extraDetail) {
          updatedData.duid = getDUID(response.data, classifiedDocument, extraDetail);
        }
        updatedData.specimens = response.data;
        updatedData.extraDetails = extraDetails;
        updatedData.specimenNotFound = false;
        dispatch(reviewResultActions.setDocumentValidation(updatedData));
      }).catch(() => {
        dispatch(reviewResultActions.setDocumentValidation({
          specimenNotFound: true,
          duid: '',
          specimens: [],
          extraDetails: [],
          extraDetail: '',
        }));
      });
    }
  }, [documentType, documentCountry]);

  const countryProps: ISelectOption = useMemo(() => {
    return {
      value: getCountryValue(documentCountry, t),
      handler: (selectedOption: IOptionType) => {
        dispatch(reviewResultActions.setDocumentValidation({ documentCountry: selectedOption.value }));
      },
      uid: uuid(),
      placeholder: t('identity.doc.country.label'),
      options: Countries.map((option) => ({
        label: `${t(option.label)} (${option.value.toUpperCase()})`,
        value: option.value,
      })),
      styledScrollBar: 'select-scroll-bar',
    };
  }, [documentCountry, i18n.language]);

  const documentTypeProps: ISelectOption = useMemo(() => {
    return {
      value: getDocumentTypeValue(documentType || '', t),
      handler: (selectedOption: IOptionType) => {
        dispatch(reviewResultActions.setDocumentValidation({ documentType: selectedOption.value }));
      },
      uid: uuid(),
      placeholder: t('identity.doc.type.label'),
      options: DocumentTypes.map((docType: IOptionType) => ({
        label: t(docType.label),
        value: docType.value,
      })),
      styledScrollBar: 'select-scroll-bar',
    };
  }, [documentType, i18n.language]);

  const extraDetailProps: ISelectOption = useMemo(() => {
    return {
      value: getExtraDetailValue(extraDetail, extraDetails),
      handler: (selectedOption: IOptionType) => {
        const extraDetail = selectedOption.value;
        const duid = getDUID(filteredSpecimens(specimens, extraDetail), classifiedDocument, extraDetail);
        dispatch(reviewResultActions.setDocumentValidation({ duid, extraDetail }));
      },
      uid: uuid(),
      placeholder: t('identity.doc.extra.label'),
      options: extraDetails || [],
      styledScrollBar: 'select-scroll-bar',
    }
  }, [specimens, extraDetail, extraDetails, i18n.language]);

  const reasonsMap = {
    imageQuality: imageQualityReasons,
    document: documentReasons,
    documentRecording: documentRecordingReasons,
  };

  const createRejectionProps = (
    key: keyof typeof reasonsMap,
    reasonOptions: IOptionType[]
  ) => ({
    value: reasonsMap[key],
    handler: (selected: IOptionType[]) => dispatch(
      reviewResultActions.setRejectionReasons({
        ...rejectionReasons,
        [key]: selected?.map((reason: IOptionType) => reason.value) || [],
      })
    ),
    uid: uuid(),
    placeholder: t('identity.doc.rejection-reason-placeholder'),
    options: reasonOptions.map((reason: IOptionType) => ({
      label: t(reason.label),
      value: reason.value,
    })),
  });

  const imageQualityRejectionReasonsProps = createRejectionProps(
    'imageQuality',
    ImageQualityRejectionReasons
  );
  const documentRejectionReasonsProps = createRejectionProps(
    'document',
    DocumentRejectionReasons
  );
  const recordRejectionReasonsProps = createRejectionProps(
    'documentRecording',
    RecordRejectionReasons
  );

  const dvRejectionReasons = useMemo(
    () => [
      {
        label: t('review-footer.doc.rejection-quality.label'),
        props: imageQualityRejectionReasonsProps,
      },
      {
        label: t('review-footer.doc.rejection-document.label'),
        props: documentRejectionReasonsProps,
      },
      {
        label: t('review-footer.doc.rejection-recording.label'),
        props: recordRejectionReasonsProps,
      },
    ],
    [imageQualityRejectionReasonsProps, documentRejectionReasonsProps, recordRejectionReasonsProps]
  )

  const handleAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      reviewResultActions.setDocumentValidation({
        result: event.target.value,
      })
    );
    if (event.target.value === EAnswers.yes) {
      dispatch(
        reviewResultActions.setRejectionReasons({
          ...rejectionReasons,
          imageQuality: [],
          document: [],
          documentRecording: [],
        })
      );
    }
  };

  const acceptances = [
    {
      flag: acceptanceChecks.documentValid,
      name: EAcceptanceCheckNames.documentValid,
      translationKeys: {
        heading: 'identity.doc.guideline.message1.tooltip',
        listItems: [
          'identity.doc.guideline.sub-message1.1.tooltip',
          'identity.doc.guideline.sub-message1.2.tooltip',
          'identity.doc.guideline.sub-message1.3.tooltip',
          'identity.doc.guideline.sub-message1.4.tooltip',
        ]
      },
    },
    {
      flag: acceptanceChecks.documentPresented,
      name: EAcceptanceCheckNames.documentPresented,
      translationKeys: {
        heading: 'identity.doc.guideline.message2.tooltip',
        listItems: [
          'identity.doc.guideline.sub-message2.1.tooltip',
          'identity.doc.guideline.sub-message2.2.tooltip',
          'identity.doc.guideline.sub-message2.3.tooltip',
          'identity.doc.guideline.sub-message2.4.tooltip',
        ]
      },
    },
    {
      flag: acceptanceChecks.documentPhysicalSigns,
      name: EAcceptanceCheckNames.documentPhysicalSigns,
      translationKeys: {
        heading: 'identity.doc.guideline.message3.tooltip',
        listItems: [
          'identity.doc.guideline.sub-message3.1.tooltip',
          'identity.doc.guideline.sub-message3.2.tooltip',
          'identity.doc.guideline.sub-message3.3.tooltip',
          'identity.doc.guideline.sub-message3.4.tooltip',
          'identity.doc.guideline.sub-message3.5.tooltip',
          'identity.doc.guideline.sub-message3.6.tooltip',
        ]
      },
    },
    {
      flag: acceptanceChecks.documentClearlyVisible,
      name: EAcceptanceCheckNames.documentClearlyVisible,
      translationKeys: {
        heading: 'identity.doc.guideline.message4.tooltip',
        listItems: [
          'identity.doc.guideline.sub-message4.1.tooltip',
          'identity.doc.guideline.sub-message4.2.tooltip',
          'identity.doc.guideline.sub-message4.3.tooltip',
          'identity.doc.guideline.sub-message4.4.tooltip',
          'identity.doc.guideline.sub-message4.5.tooltip',
          'identity.doc.guideline.sub-message4.6.tooltip',
          'identity.doc.guideline.sub-message4.7.tooltip',
        ]
      },
    },
  ];

  const fullFrameFirstPageImageWrapper = useMemo(() => <ImageWrapper
    transactionCode={transactionCode}
    filetype={EFileTypes.fullFrameFirstPage}
    filename={filenames.fullFrameFirstPage || ''}
    setFilename={setFilename}
  />, []);

  const fullFrameSecondPageImageWrapper = useMemo(() => <ImageWrapper
    transactionCode={transactionCode}
    filetype={EFileTypes.fullFrameSecondPage}
    filename={filenames.fullFrameSecondPage || ''}
    setFilename={setFilename}
  />, []);

  const signatureImageWrapper = useMemo(() => <ImageWrapper
    transactionCode={transactionCode}
    filetype={EFileTypes.signatureImage}
    filename={filenames.nfcSignaturePhoto || filenames.signatureImage || ''}
    setFilename={setFilename}
  />, []);

  const firstImageComparison = useMemo(() => <Comparison
    specimenImage={!specimenNotFound ? currentSpecimen?.front || '' : ''}
    transactionCode={transactionCode}
    filename={filenames.firstImage || ''}
    setFilename={setFilename}
    externalSource={!!currentSpecimen?.externalSource}
  />, [duid, currentSpecimen, specimenNotFound]);

  const secondImageComparison = useMemo(() => <Comparison
    specimenImage={!specimenNotFound ? currentSpecimen?.back || '' : ''}
    transactionCode={transactionCode}
    filename={filenames.secondImage || ''}
    setFilename={setFilename}
    externalSource={!!currentSpecimen?.externalSource}
  />, [duid, currentSpecimen, specimenNotFound]);

  const firstVideoPlayer = useMemo(() => <VideoPlayer
    filename={filenames.firstVideo || ''}
    filetype={EFileTypes.firstVideo}
    transactionCode={transactionCode}
  />, []);

  const secondVideoPlayer = useMemo(() => <VideoPlayer
    filename={filenames.secondVideo || ''}
    filetype={EFileTypes.secondVideo}
    transactionCode={transactionCode}
  />, []);

  const openSpecimenPopup = duid || specimenNotFound;

  return (
    <>
      <div className='document-validation-section'>
        <h1>{t('identity.doc.heading')}</h1>
        <div className='clarifier-selection-section'>
          <div>
            <p>{t('identity.doc.country.label')}</p>
            <SelectOption {...countryProps} />
          </div>
          <div>
            <p>{t('identity.doc.type.label')}</p>
            <SelectOption {...documentTypeProps} />
          </div>
          {!!extraDetails.length && (
            <div>
              <p>{t('identity.doc.extra.label')}</p>
              <SelectOption {...extraDetailProps} />
            </div>
          )}
        </div>
        <div className='document-validation'>
          <div className='document-validation-image-section'>
            <div className='document-validation-image-container'>
              <h2>{t('identity.doc.first-page.title')}</h2>
              <div className='image-wrapper-container'>
                {fullFrameFirstPageImageWrapper}
              </div>
              <div className='doc-validation-modal-button'>
                <SpecimenPopup
                  className={`${!openSpecimenPopup ? 'is-disabled' : ''}`}
                  onClick={() => openSpecimenPopup && setModalShow(true)}
                />
              </div>
              <div className='document-validation-image-comparison'>
                {firstImageComparison}
              </div>
              <div className='video-player-section'>
                {firstVideoPlayer}
              </div>
            </div>
            <div className='document-validation-image-container'>
              <h2>{t('identity.doc.second-page.title')}</h2>
              <div className='image-wrapper-container'>
                {fullFrameSecondPageImageWrapper}
              </div>
              <div className='doc-validation-modal-button'>
                <SpecimenPopup
                  className={`${!openSpecimenPopup ? 'is-disabled' : ''}`}
                  onClick={() => openSpecimenPopup && setModalShow(true)}
                />
              </div>
              <div className='document-validation-image-comparison'>
                {secondImageComparison}
              </div>
              <div className='video-player-section'>
                {secondVideoPlayer}
              </div>
            </div>
          </div>
          {!!filename && <Modal
            show={!!filename}
            onHide={() => setFilename('')}
          >
            <div className='face-image-wrapper-container'>
              <ImageWrapper
                transactionCode={transactionCode}
                filetype={EFileTypes.firstImage}
                filename={filename}
                enableZoom={true}
                original={true}
              />
            </div>
          </Modal>}
          {modalShow && (
            <Modal
              show={modalShow}
              onHide={() => setModalShow(false)}
              heading='specimen.heading'
            >
              <Specimen
                firstImage={filenames.firstImage || ''}
                secondImage={filenames.secondImage || ''}
                onHide={() => setModalShow(false)}
                specimens={specimens.filter((item) => !item.extraDetail || item.extraDetail === extraDetail) || []}
              />
            </Modal>
          )}
          <div className='document-validation-verification-section'>
            <h2>{t('identity.doc.signature.title')}</h2>
            <div className='document-validation-verification-image-container'>
              <div className='image-wrapper-container'>
                {signatureImageWrapper}
              </div>
            </div>
            {duidValidated() && <DataVerification
              documentType={documentType}
              documentCountry={documentCountry}
              extraLabel={DUID_LABEL_MAPPING[duid] || ''}
            />}
          </div>
        </div>
        <div className='review-section-container'>
          <div className='line'></div>
          <h2>{t('identity.doc.guideline.heading')}</h2>
          {acceptances.map((item, index) => (
            <div className='checkbox-section' key={index}>
              <input
                type='checkbox'
                checked={item.flag}
                onChange={(event) => {
                  if (!event.target.checked &&
                    !hasActiveAcceptanceCheck(acceptanceChecks,
                      [
                        EAcceptanceCheckNames.documentPresented,
                        EAcceptanceCheckNames.documentValid,
                        EAcceptanceCheckNames.documentPhysicalSigns,
                        EAcceptanceCheckNames.documentClearlyVisible
                      ],
                      item.name)
                  ) {
                    dispatch(batchActions([
                      reviewResultActions.setDocumentValidation({
                        ...documentValidationReview[0],
                        result: '',
                      }),
                      reviewResultActions.setRejectionReasons({
                        ...rejectionReasons,
                        imageQuality: [],
                        document: [],
                        documentRecording: [],
                      }),
                    ]));
                  }
                  dispatch(
                    reviewResultActions.setAcceptanceChecks({
                      ...acceptanceChecks,
                      [item.name]: event.target.checked,
                    })
                  )
                }
                }
              />
              <p className='review-description'>
                {t(`identity.doc.guideline.message${index + 1}`)}
                <ToolTip
                  placement='top'
                  tooltipValue={
                    <>
                      <span>{t(item.translationKeys.heading)}</span>
                      <ul>
                        {
                          item.translationKeys.listItems.map((key: string, index: number) => (
                            <li key={index}>{t(key)}</li>
                          ))
                        }
                      </ul>
                    </>
                  }
                >
                  <Info />
                </ToolTip>
              </p>
            </div>
          ))}
          <div className='line'></div>
          <div className='review-buttons'>
            <h2 className='description'>
              {t('review-footer.doc.question.title')}
            </h2>
            <div className='answer-section'>
              <Form.Check
                inline
                label={t('review-footer.answer-yes.title')}
                value={EAnswers.yes}
                name='identity.doc.answer'
                className='radio-button'
                onChange={handleAnswerChange}
                type='radio'
                disabled={!acceptances.some((item) => item.flag)}
                checked={result === EAnswers.yes}
              />
              <Form.Check
                inline
                label={t('review-footer.answer-no.title')}
                value={EAnswers.no}
                name='identity.doc.answer'
                className='radio-button'
                onChange={handleAnswerChange}
                type='radio'
                disabled={!acceptances.some((item) => item.flag)}
                checked={result === EAnswers.no}
              />
            </div>
            <div className='select-reasons'>
              <h2>{t('identity.doc.rejection-reason.title')}</h2>
              <div className='multi-select-container'>
                {dvRejectionReasons.map((reason, index) => (
                  <div className='multi-select-item' key={index}>
                    <p>{reason.label}</p>
                    <MultipleSelect
                      disabled={!(acceptances.some((item) => item.flag) && result === EAnswers.no)}
                      {...reason.props}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentValidation;
